






















































































import { formatDate, timeAgo } from "@/plugins/dayjs";
import { defineComponent } from "@vue/composition-api";
import AccountSettingsForm from "@/components/forms/AccountSettingsForm.vue";

export default defineComponent({
  name: "AccountSettings",
  components: {
    AccountSettingsForm,
  },
  data () {
    return {
      editAccountModal: false,
      isRouterAlive: true,
      formValue: false,
    };
  },
  computed: {
    user () {
      return this.$store.state.user;
    },
  },
  methods: {
    accountUpdated () {
      this.$store.dispatch("user/setUserDetails");
      this.editAccountModal = false;
    },
    changeF(newVal: any) {
      this.formValue = newVal;
    },
    closeDialog() {
      if (this.formValue) {
        this.$confirm(
        /* Message */
        this.$i18n.t("messages.sure_to_close") as string,
        /* Title */
        this.$i18n.t("labels.warning") as string,
        {
          confirmButtonText: "OK",
          cancelButtonText: this.$i18n.t("labels.cancel") as string,
          type: "warning",
          center: true,
          showClose: false,
          dangerouslyUseHTMLString: true,

          beforeClose: async (action, instance, done) => {
            if (action === "confirm") {
              instance.confirmButtonLoading = true;
              this.isRouterAlive = false;
              this.$nextTick(() => {
                this.isRouterAlive = true;
              });
              this.editAccountModal = false;
              instance.confirmButtonLoading = false;
              done();
              /*  */
            } else {
              done();
            }
          },
        });
      } else {
        this.isRouterAlive = false;
        this.$nextTick(() => {
          this.isRouterAlive = true;
        });
        this.editAccountModal = false;
        this.formValue = false;
      }
    },
  },
  setup () {
    return { formatDate, timeAgo };
  },
});
